import React from 'react';
import './UnderMaintenance.css'; 
import icon from './maintain-icon.png'

const UnderMaintenance = () => {
    return (
        <div className="maintenance-container">
            <div className="maintenance-content">
                <img
                    src={icon}
                    alt="Maintenance Icon"
                    className="maintenance-icon"
                />
                <h1>We're Under Maintenance</h1>
                <p>Sorry for the inconvenience. We're performing some maintenance at the moment. We'll be back up shortly!</p>
            </div>
        </div>
    );
};

export default UnderMaintenance;
