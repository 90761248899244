// import Intro from './components/Intro/Intro'
// import About from './components/about/About'
// import Certs from './components/Certs/Certs'
// import ProductList from './components/productList/ProductList';
// import ContactPage from './components/contact/ContactPage';
// import './App.css';
// import Footer from './components/Footer/Footer';
// import Navbar from './components/Navbar/Navbar';
import UnderMaintenance from './components/Maintain/UnderMaintenance';

function App() {
  return (
    <UnderMaintenance/>
    // <div className="App">
    //   <Navbar />
    //   <div id="home">
    //     <Intro />
    //   </div>
    //   <div id="about">
    //     <About />
    //   </div>
    //   <div id="certifications">
    //     <Certs />
    //   </div>
    //   <div id="projects">
    //     <ProductList />
    //   </div>
    //   <div id="contact">
    //     <ContactPage />
    //   </div>
    //   <Footer />
    // </div>
  );
}

export default App;
